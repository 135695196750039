.button:hover {
    background: #dadce0;
    cursor: pointer;
}

.selected {
    background: #dadce0;
}

.large {
    height: 63px;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 2px solid #dadce0;
    border-radius: 6px;
}

.medium {
    height: 40px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 2px solid #dadce0;
    border-radius: 4px;
}

.small {
    height: 30px;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 3px;
    border: 1px solid #dadce0;
    border-radius: 4px;
}

.typography {
    margin-left: 2px;
    margin-right: 2px;
    flex-grow: 1;
}

.closelarge {
    padding: 2px !important;
    margin-right: 12px !important;
}

.closemedium {
    padding: 0px !important;
    margin-right: 6px !important;
}

.closesmall {
    padding: 0px !important;
    margin-right: 12px !important;
}

.disabled {
    border-style: dotted !important;
    pointer-events: none;
    opacity: 0.7;
}
