html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
}

.transition-wrap-enter-done {
    position: relative;
}

.dialogMobileRoot {
    z-index: 2001 !important;
}

// left
.left .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    transition: none;
}

.left .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
}

.left .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

// right
.right .transition-wrap-enter {
    position: absolute;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    transition: none;
}

.right .transition-wrap-exit {
    position: absolute;
    opacity: 1;
    transform: translate3d(100%, 0, 0);
}

.right .transition-wrap-exit-active {
    position: absolute;
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.MuiAutocomplete-popper {
    z-index: 2300 !important;
}
